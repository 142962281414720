<template>
    <svg
        width="1000"
        height="1000"
        viewBox="0 0 1000 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5_17)">
            <path
                d="M310.242 477.026H263.879V200H310.242V301.088C324.537 284.497 350.036 270.607 385.194 270.607C441.602 270.607 473.283 309.962 473.283 366.679V477.026H426.92V374.395C426.92 338.513 408.375 313.048 370.899 313.048C331.491 313.048 310.242 337.742 310.242 375.167V477.026Z"
                fill="currentColor" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 375.939C0 313.048 51.385 270.607 115.906 270.607C180.041 270.607 231.426 313.048 231.426 375.939C231.426 438.829 180.041 481.27 115.906 481.27C51.385 481.27 0 438.829 0 375.939ZM44.4306 375.939C44.4306 417.223 76.1116 441.53 115.906 441.53C155.314 441.53 186.995 417.223 186.995 375.939C186.995 334.655 155.314 310.348 115.906 310.348C76.1116 310.348 44.4306 334.655 44.4306 375.939Z"
                fill="currentColor" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M263.997 770.291V795.756H310.359V518.73H263.997V619.046C249.315 603.226 222.271 589.337 185.181 589.337C115.251 589.337 73.9111 637.18 73.9111 694.668C73.9111 752.157 115.251 800 185.181 800C222.271 800 249.315 786.11 263.997 770.291ZM266.315 696.212C266.315 733.251 233.861 761.417 191.749 761.417C151.954 761.417 117.955 737.495 117.955 694.668C117.955 651.841 151.954 627.92 191.749 627.92C233.861 627.92 266.315 656.085 266.315 693.125V696.212Z"
                fill="currentColor" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M386.857 708.172C393.039 740.582 422.015 760.645 459.878 760.645C493.491 760.645 518.217 743.669 527.876 718.976L564.966 738.653C552.603 770.291 514.354 800 459.878 800C392.266 800 342.813 757.173 342.813 694.668C342.813 635.25 390.721 589.337 459.878 589.337C526.717 589.337 568.83 633.707 568.83 691.967V708.172H386.857ZM524.013 673.448C520.922 652.227 495.809 627.92 457.56 627.92C425.493 627.92 397.675 643.739 388.789 673.448H524.013Z"
                fill="currentColor" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M672.372 800C710.235 800 741.53 786.882 759.688 768.362C766.256 788.425 785.96 804.63 829.232 795.756V759.874C804.505 765.275 799.096 760.26 799.096 748.685V663.416C799.096 615.187 763.552 589.337 700.962 589.337C651.896 589.337 607.465 611.715 594.715 645.668L632.964 662.644C640.691 642.967 668.123 628.691 699.417 628.691C737.28 628.691 754.279 643.353 754.279 662.644V664.188L666.191 674.605C617.51 680.393 588.147 701.613 588.147 737.11C588.147 778.779 625.624 800 672.372 800ZM754.279 717.432C754.279 747.141 711.008 762.574 676.236 762.574C649.578 762.574 633.351 753.7 633.351 736.724C633.351 719.747 646.873 713.188 673.918 710.102L754.279 700.456V717.432Z"
                fill="currentColor" />
            <path
                d="M977.205 592.037C986.478 592.037 994.205 593.581 1000 595.124V641.424L999.042 641.184C990.422 639.029 986.112 637.951 976.046 637.951C929.684 637.951 899.548 657.629 899.548 705.857V795.756H853.186V593.195H899.548V629.849C916.934 602.455 944.365 592.037 977.205 592.037Z"
                fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_5_17">
                <rect
                    width="1000"
                    height="600"
                    fill="white"
                    transform="translate(0 200)" />
            </clipPath>
        </defs>
    </svg>
</template>
